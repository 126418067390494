import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import Image from 'next/legacy/image';

import { addBookmark, removeBookmark } from 'ducks/client/bookmarks';
import { getWeekdayText } from 'lib/util/getWeekdayText';
import { useCurrencyFormatter } from 'hooks/useCurrencyFormatter';
import { PMPProductCardViewProps } from 'lib/themes/themes';
import { getImageUrl } from 'lib/util/imageUrl';
import { formattedTotalBookedText } from 'lib/util/formattedTotalBookedText';
import { useCustomerLedgerEnabled } from 'hooks/useCustomerLedgerEnabled';

import baseStyles from '../base.module.css';
import styles from './PMPProductCardView.module.css';

const PMPProductCardView = ({
  product,
  isBookmarked,
  ranking,
  displayLabelTypes,
  isRecommended,
  discountText,
  shouldShowTotalBooked,
  tags,
}: PMPProductCardViewProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currencyFormatter = useCurrencyFormatter();
  const customerLedgerEnabled = useCustomerLedgerEnabled();

  return (
    <div className={styles['c-card']}>
      <div className={styles['item__li__pic']}>
        {product.imageURL && (
          <Image
            className={styles['item__li__pic__img']}
            layout="fill"
            src={product.imageURL}
            loader={({ src, width }) => getImageUrl(src, width)}
            alt="product-thumbnail"
          />
        )}
        <div className={styles['item__li__sub']}>
          <div className={clsx(styles['item__li__sub__erea'], baseStyles['color-bg'])}>
            <svg
              className={baseStyles['color-fill']}
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
            >
              <path d="M0,32V0H32A32,32,0,0,0,0,32Z" />
            </svg>
            <p className={baseStyles['color-sub--txt']}>{product.location}</p>
            <svg
              className={baseStyles['color-fill']}
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
            >
              <path d="M0,32V0H32A32,32,0,0,0,0,32Z" />
            </svg>
          </div>
        </div>
        <label className={styles['c-card__pic__favo']}>
          <input
            type="checkbox"
            onClick={(e) => e.stopPropagation()}
            onChange={() => {
              isBookmarked
                ? dispatch(removeBookmark(product.id))
                : dispatch(addBookmark(product.id));
            }}
            checked={isBookmarked}
          />
          <img
            className={clsx(styles['c-card__pic__favo__img'], styles['on'])}
            src="/static/images/ic_favo_on.svg"
            alt="favorite on"
          />
          <img
            className={clsx(styles['c-card__pic__favo__img'], styles['off'])}
            src="/static/images/ic_favo_off.svg"
            alt="favorite off"
          />
        </label>
        <div className={styles['c-card__pic__labelframe']}>
          {!!ranking && displayLabelTypes.includes('RANKING_GENERAL') && (
            <div className={styles['c-card__pic__labelframe__ranking']}>
              <div>
                <img src="/static/images/ic_ranking_white.svg" alt="ranking" />
              </div>
              <p>{t('High Ranking')}</p>
            </div>
          )}
          {isRecommended && displayLabelTypes.includes('RECOMMENDED') && (
            <div className={styles['c-card__pic__labelframe__recommend']}>
              <div>
                <img src="/static/images/ic_recommend_white.svg" alt="recommend" />
              </div>
              <p>{t('Recommended')}</p>
            </div>
          )}
          {customerLedgerEnabled && product.shouldShowOnlyForLoggedInCustomer && (
            <div className={styles['c-card__pic__labelframe__recommend']}>
              <div>
                <img src="/static/images/ic_recommend_white.svg" alt="recommend" />
              </div>
              <p>{t('Members Only')}</p>
            </div>
          )}
          {!!discountText && displayLabelTypes.includes('PROMOTION') && (
            <div className={styles['c-card__pic__labelframe__promotion']}>{discountText}</div>
          )}
          {!!ranking && displayLabelTypes.includes('RANKING_SPECIFIC') && (
            <div className={styles['c-card__pic__labelframe__rankingnumber']}>
              <div>
                <img src="/static/images/ic_ranking_white.svg" alt="ranking" />
              </div>
              <p>{ranking}</p>
            </div>
          )}
        </div>
      </div>
      <div className={styles['c-card__header']}>
        {product.reviewCount != null && product.reviewCount != 0 && (
          <div className={styles['c-card__header__star']}>
            <img src="/static/images/ic_star_on.svg" alt="star" />
            <span>{(product.reviewRating ?? 0).toFixed(1)}</span>
            <span>({product.reviewCount})</span>
          </div>
        )}
        {shouldShowTotalBooked && Boolean(product.totalBooked) && (
          <p className={styles['c-card__header__number']}>
            {formattedTotalBookedText(product.totalBooked, t)}
          </p>
        )}
        <div
          className={styles['c-card__header__tags']}
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          {tags.length > 0 && (
            <>
              <div className={clsx(styles['c-card__header__tags__txt'], 'subcolor-bg')}>
                <div className="maincolor-ic">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                  >
                    <rect width="32" height="32" fill="none" />
                    <g>
                      <path
                        d="M14.17,2.27H4.27a2,2,0,0,0-2,2v9.9a2,2,0,0,0,.59,1.42L16.41,29.14a2,2,0,0,0,2.83,0l9.9-9.9a2,2,0,0,0,0-2.83L15.59,2.86A2,2,0,0,0,14.17,2.27Zm-3.41,8.49a3,3,0,1,1,0-4.25A3,3,0,0,1,10.76,10.76Z"
                        fill="#0094cc"
                      />
                    </g>
                  </svg>
                </div>
                {t('View')}
              </div>
              <ul className={clsx(styles['c-card__header__tags__list'], 'maincolor-border')}>
                {tags.map((tag) => (
                  <li key={tag}>
                    <p>{tag}</p>
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      </div>
      <h3 className={clsx(styles['c-card__ttl'], 'maincolor-txt')}>{product.name}</h3>
      <p className={styles['c-card__description']}>{product.description}</p>
      <div className={styles['c-card__bottom']}>
        <p className={styles['c-card__bottom__week']}>
          {t('Days of week: {{operatingDaysText}}', {
            operatingDaysText: product.operatingWeekdays
              .map((weekday) => getWeekdayText(weekday, t))
              .join(','),
          })}
        </p>
        <p className={clsx(styles['c-card__bottom__price'], 'maincolor-txt')}>
          {currencyFormatter.format(product.lowPrice)}
        </p>
      </div>
    </div>
  );
};

export default PMPProductCardView;
